//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-948:_7088,_2620,_6260,_2229,_4309,_9432,_4680,_3912;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-948')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-948', "_7088,_2620,_6260,_2229,_4309,_9432,_4680,_3912");
        }
      }catch (ex) {
        console.error(ex);
      }